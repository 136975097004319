/*  ==========================================================================
    QUICK REQUEST V1
    ========================================================================== */

@mixin quick-request--v1() {
  background-color: $clr__ci--secondary;
  bottom: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $unit__zi--quick-request;
  transition: height 0.75s;
  > .bn-grid__row {
    height: 100%;
  }
  .bn-quick-request__title,
  .bn-quick-request__form {
    align-items: center;
    display: flex;
  }
  .bn-quick-request__title {
    color: $clr__white;
    display: none;
    font-size: 2.0em;
    max-width: 15%;
    text-transform: uppercase;
    > div {
      width: 100%;
    }
  }
  .bn-quick-request__form {
    @include quick-request--horizontal();
  }
  &.bn-quick-request--small {
    width: calc(100% - #{$unit__wdt--header-v3-sidebar-desktop-large});
    .bn-quick-request__title {
      display: none;
    }
  }
  &.bn-quick-request--visible {
    border-top: 1px solid $clr--white;
    height: $unit__hgt--quick-request-v1;
    overflow: visible;
    transition: height 0.75s;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    &.bn-quick-request--small {
      width: calc(100% - #{$unit__wdt--header-v3-sidebar});
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-quick-request__title {
      display: none;
    }
  }
  // tablet
  @media #{$mq__tablet} {
    display: none;
  }
}