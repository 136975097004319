/*  ==========================================================================
    BOOKING
    ========================================================================== */

/*
 * COLORS
 */

// ...

/*
 * MODULE
 */

.bn-booking {
  .bn-booking__widget {
    font-size: 16px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// ...