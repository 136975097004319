/*  ==========================================================================
    ROOMS LIST VIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__rooms-list-view--accent: $clr__ci--primary;
$module-clr__rooms-list-view--bg: $clr__ci--secondary;
$module-clr__rooms-list-view--typo: $clr__typo--secondary;

/*
 * MODULE
 */

.bn-rooms-list-view {
  .bn-rooms-list-view__tabs {
    padding-bottom: 70px;
    text-align: center;
    > div {
      display: inline-block;
      padding: 10px;
    }
    .bn-button {
      @include button--reset();
      @include button--default-inverted();
      &.bn-button--act {
        background-color: $clr__ci--primary !important;
        border-color: $clr__ci--primary !important;
        color: $clr__typo--secondary !important;
      }
    }
  }
  .bn-rooms-list-view__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-room-box {
      color: $module-clr__rooms-list-view--typo;
      margin-top: 40px;
      padding-top: 65px;
      width: calc(50% - 10px);
      a {
        color: $module-clr__rooms-list-view--typo;
        &:visited {
          color: $module-clr__rooms-list-view--typo;
        }
        &:hover {
          color: $module-clr__rooms-list-view--typo;
        }
      }
      > div {
        display: block;
        position: relative;
      }
      .bn-room-box__image {
        background-color: $clr__black;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
        img {
          @include img--cover-v2();
        }
      }
      .bn-room-box__content {
        align-items: center;
        background-color: rgba($clr__typo--primary,0.5);
        display: flex;
        min-height: 450px;
        padding: 20px;
        position: relative;
        text-align: center;
        z-index: 5;
        > div {
          width: 100%;
        }
        .bn-room-box__name {
          @include typo--subheadline($module-clr__rooms-list-view--typo);
        }
        .bn-room-box__stats {
          font-size: 1.6em;
          margin-top: 20px;
        }
        .bn-room-box__button {
          margin-top: 20px;
          .bn-button {
            @include button--custom($module-clr__rooms-list-view--typo,$module-clr__rooms-list-view--typo,$clr__typo--primary,transparent,$module-clr__rooms-list-view--typo,$module-clr__rooms-list-view--typo);
          }
        }
        // ie 11 fix for "align-items: center" with a "min-height"
        &:after{
          content: '';
          font-size: 0;
          min-height: inherit;
        }
      }
      .bn-room-box__labels {
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        top: -65px;
        width: 100%;
        z-index: 10;
        .bn-room-box__label {
          background-color: $module-clr__rooms-list-view--bg;
          display: block;
          height: 130px;
          margin-left: 20px;
          min-width: 130px;
          overflow: hidden;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-icon {
            display: inline-block;
            font-size: 3.0em;
            margin-bottom: 10px;
          }
          .bn-room-box__amount-addition,
          .bn-room-box__max-persons {
            font-size: 1.6em;
          }
          .bn-room-box__amount-addition {
            margin-right: 5px;
          }
          .bn-room-box__amount {
            font-size: 2.5em;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      // remove margin top on first and second box
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
  }
  &.bn-rooms-list-view--filtered {
    .bn-rooms-list-view__holder {
      .bn-room-box {
        display: none;
        &.bn-room-box--act {
          display: block;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// desktop small
@media #{$mq__desktop--small} {
  .bn-rooms-list-view {
    .bn-rooms-list-view__holder {
      .bn-room-box {
        .bn-room-box__content {
          min-height: 400px;
        }
      }
    }
  }
}

// laptop
@media #{$mq__laptop} {
  .bn-rooms-list-view {
    .bn-rooms-list-view__holder {
      .bn-room-box {
        padding-top: 50px;
        .bn-room-box__content {
          min-height: 350px;
        }
        .bn-room-box__labels {
          top: -50px;
          .bn-room-box__label {
            height: 100px;
            margin-left: 10px;
            min-width: 100px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-rooms-list-view {
    .bn-rooms-list-view__holder {
      display: block;
      .bn-room-box {
        margin-top: 40px !important;
        width: 100%;
        .bn-room-box__content {
          min-height: 400px;
        }
        .bn-room-box__labels {
          .bn-room-box__label {
            .bn-icon {
              font-size: 2.5em;
            }
            .bn-room-box__amount-addition,
            .bn-room-box__max-persons {
              font-size: 1.4em;
            }
            .bn-room-box__amount {
              font-size: 2.0em;
            }
          }
        }
        &:first-child {
          margin-top: 0 !important;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-rooms-list-view {
    .bn-rooms-list-view__tabs {
      padding-bottom: 30px;
      > div {
        display: block;
      }
    }
    .bn-rooms-list-view__holder {
      .bn-room-box {
        margin-top: 20px !important;
        .bn-room-box__content {
          min-height: 350px;
          .bn-room-box__button {
            margin-top: 20px;
          }
        }
        &:first-child {
          margin-top: 0 !important;
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-rooms-list-view {
    .bn-rooms-list-view__tabs {
      padding-bottom: 15px;
      > div {
        padding: 5px;
      }
    }
    .bn-rooms-list-view__holder {
      .bn-room-box {
        padding-top: 40px;
        .bn-room-box__content {
          min-height: 250px;
          .bn-room-box__stats {
            margin-top: 10px;
          }
          .bn-room-box__button {
            margin-top: 10px;
            .bn-button {
              min-width: 0;
              width: 100%;
            }
          }
        }
        .bn-room-box__labels {
          top: -40px;
          .bn-room-box__label {
            height: 80px;
            min-width: 80px;
            .bn-icon {
              font-size: 1.6em;
              margin-bottom: 5px;
            }
            .bn-room-box__amount-addition,
            .bn-room-box__max-persons {
              font-size: 1.2em;
            }
            .bn-room-box__amount {
              font-size: 1.6em;
            }
            &.bn-room-box__label--persons {
              display: none;
            }
          }
        }
      }
    }
  }
}