/*  ==========================================================================
    FOOTER V1
    ========================================================================== */

@mixin footer--v1() {
  padding-bottom: calc(#{$unit__hgt--footer-fixed-bar} + 60px);
  // footer top
  .bn-f-top {
    background-color: rgba($clr__ci--primary,0.1);
    padding: 40px 0;
    .bn-f-top__boxes {
      justify-content: space-between;
      padding-top: 20px;
      .bn-f-top__box {
        align-items: center;
        background-color: $clr__ci--primary;
        color: $clr__typo--secondary;
        display: flex;
        min-height: 250px;
        a {
          color: $clr__typo--secondary;
          &:visited {
            color: $clr__typo--secondary;
          }
          &:hover {
            color: rgba($clr__typo--secondary, 0.5);
          }
        }
        &.bn-f-top__box--1,
        &.bn-f-top__box--2 {
          max-width: calc(30% - 20px);
        }
        &.bn-f-top__box--3 {
          max-width: 40%;
        }
      }
      // accordion
      .bn-accordion {
        .bn-accordion__trigger {
          display: none;
        }
      }
    }
    .bn-f-top__credits {
      .bn-f-top__credits__right {
        padding-top: 20px;
      }
    }
  }
  // footer bottom
  .bn-f-bottom {
    .bn-f-bottom__partners {
    }
    .bn-f-bottom__useful-sites {
    }
  }
  // weather teaser
  .bn-weather-teaser {
    &.bn-weather-teaser--small {
      color: $clr__ci--primary;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.6em;
      .bn-weather-teaser__title,
      .bn-weather-teaser__forecast {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .bn-weather-teaser__forecast {
        margin-left: 10px;
        .bn-loader {
          font-size: 1.0em;
        }
        .weather-icon {
          font-size: 1.3em;
          padding: 0 5px;
        }
      }
    }
  }
  // address
  .bn-address {
    &.bn-address--v1 {
      font-size: 1.6em;
      font-style: normal;
      line-height: 1.6;
      padding: 50px 40px 50px 70px;
      width: 100%;
      .bn-address__item {
        &.bn-address__company {
          font-weight: $typo__fw--bold;
        }
        &.bn-address__item--de,
        &.bn-address__item--en,
        &.bn-address__item--it {
          display: none;
        }
      }
      &.bn-address--de {
        .bn-address__item {
          &.bn-address__item--de {
            display: inline;
          }
        }
      }
      &.bn-address--en {
        .bn-address__item {
          &.bn-address__item--en {
            display: inline;
          }
        }
      }
      &.bn-address--it {
        .bn-address__item {
          &.bn-address__item--it {
            display: inline;
          }
        }
      }
    }
  }
  // map
  .bn-map {
    height: 100%;
    position: relative;
    width: 100%;
    .bn-map__canvas {
      align-items: center;
      background-color: $clr__black;
      display: flex;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .bn-button {
      &.bn-button--getting-there {
        @include button--custom($clr__ci--primary,$clr__ci--primary,$clr__typo--secondary,$clr__white,$clr__white,$clr__ci--primary);
        bottom: 20px;
        max-width: 250px;
        min-width: 0;
        position: absolute;
        right: 20px;
        width: calc(100% - 40px);
        z-index: 5;
      }
    }
  }
  // newsletter
  .bn-newsletter {
    &.bn-newsletter--small {
      padding: 20px 40px;
      width: 100%;
      .bn-newsletter__title {
        font-size: 1.6em;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .bn-newsletter__form {
        .bn-loader {
          color: $clr__typo--secondary;
        }
      }
    }
  }
  // references
  .bn-references {
    font-size: 3.0em;
    a {
      color: $clr__ci--primary;
      &:visited {
        color: $clr__ci--primary;
      }
      &:hover {
        color: $clr__ci--secondary;
      }
    }
    .bn-references__item {
      display: inline-block;
      margin: 20px 40px 0 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  // legal menu
  .bn-legal-menu {
    &.bn-legal-menu--v1 {
      font-size: 1.4em;
      text-align: right;
      a {
        color: $clr__typo--primary;
        &:visited {
          color: $clr__typo--primary;
        }
        &:hover {
          color: $clr__ci--primary;
        }
      }
      .bn-break {
        display: none;
      }
      .bn-legal-menu__item {
        &.bn-legal-menu__item--cur {
          color: $clr__ci--primary;
        }
      }
      .bn-powered-by {
        color: $clr__black;
        display: inline-block;
        margin-top: 10px;
        &:visited {
          color: $clr__black;
        }
        &:hover {
          color: $clr__bn-red;
        }
      }
    }
  }
  // partners
  .bn-partners {
    padding-top: 40px;
    text-align: center;
    .bn-partners__item {
      display: inline-block;
      margin-top: 20px;
      padding: 0 40px;
      img {
        @include img--rendering-crisp-edges();
        height: 80px;
        width: auto;
      }
    }
  }
  // useful pages
  .bn-useful-sites {
    font-size: 1.4em;
    padding-top: 60px;
    text-align: center;
    a {
      color: $clr__typo--primary;
      .bn-useful-sites__comma {
        color: $clr__typo--primary;
      }
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-useful-sites__title {
      display: inline-block;
      font-weight: $typo__fw--bold;
      margin-right: 5px;
    }
  }
  // view switcher
  .bn-view-switcher {
    display: none !important; //remove !important if you want enable version switcher
    font-size: 1.4em;
    font-style: italic;
    padding-top: 60px;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $clr__typo--primary;
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-view-switcher__trigger {
      &.bn-view-switcher__trigger--web {
        display: none;
      }
    }
    &.bn-view-switcher--view-switched {
      display: block;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 70px;
        }
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 60px;
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    // partners
    .bn-partners {
      .bn-partners__item {
        padding: 0 20px;
        img {
          height: 50px;
        }
      }
    }
    // address
    .bn-address {
      &.bn-address--v1 {
        padding: 20px;
      }
    }
    // newsletter
    .bn-newsletter {
      &.bn-newsletter--small {
        padding: 20px;
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    padding-bottom: 130px;
    // top
    .bn-f-top {
      .bn-f-top__boxes {
        padding-top: 10px;
        .bn-f-top__box {
          flex: none;
          &.bn-f-top__box--1,
          &.bn-f-top__box--2 {
            max-width: 100%;
            width: calc(50% - 10px);
          }
          &.bn-f-top__box--3 {
            margin-top: 20px;
            max-width: 100%;
            width: 100%;
          }
        }
      }
      .bn-f-top__credits {
        flex-direction: column;
      }
    }
    // references
    .bn-references {
      text-align: center;
      .bn-references__item {
        margin-right: 0;
        padding: 0 20px;
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        text-align: center;
        .bn-powered-by {
          margin-top: 20px;
        }
      }
    }
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 40px;
        }
      }
    }
    // useful pages
    .bn-useful-sites {
      .bn-useful-sites__title {
        display: block;
        margin-right: 0;
      }
    }
    // view switcher
    .bn-view-switcher {
      display: block;
      .bn-view-switcher__trigger {
        &.bn-view-switcher__trigger--mobile {
          display: none;
        }
        &.bn-view-switcher__trigger--web {
          display: inline;
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    // top
    .bn-f-top {
      .bn-f-top__boxes {
        display: block;
        .bn-f-top__box {
          &.bn-f-top__box--1,
          &.bn-f-top__box--2 {
            width: 100%;
          }
          &.bn-f-top__box--1 {
            min-height: 0;
          }
          &.bn-f-top__box--2 {
            margin-top: 20px;
          }
        }
        // accordion
        .bn-accordion {
          display: block;
          min-height: 0;
          .bn-accordion__trigger {
            cursor: pointer;
            display: block;
            line-height: 20px;
            padding: 15px 60px 15px 20px;
            position: relative;
            text-transform: uppercase;
            .bn-accordion__title {
              font-size: 1.6em;
            }
            .bn-accordion__state {
              font-size: 1.8em;
              height: 20px;
              line-height: 20px;
              margin-top: -10px;
              position: absolute;
              right: 20px;
              top: 50%;
              text-align: center;
              width: 20px;
              z-index: 5;
              .bn-icon {
                &.bn-icon--arrow-top {
                  display: none;
                }
              }
            }
            &:hover {
              background-color: $clr__ci--secondary;
            }
            &.bn-accordion__trigger--act {
              .bn-accordion__state {
                font-size: 1.8em;
                height: 20px;
                line-height: 20px;
                margin-top: -10px;
                position: absolute;
                right: 20px;
                top: 50%;
                text-align: center;
                width: 20px;
                z-index: 5;
                .bn-icon {
                  &.bn-icon--arrow-bottom {
                    display: none;
                  }
                  &.bn-icon--arrow-top {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .bn-accordion__slide {
            align-items: center;
            display: none;
            min-height: 250px;
            overflow: hidden;
            &.bn-accordion__slide--open {
              display: flex;
            }
          }
        }
      }
    }
    // weather teaser
    .bn-weather-teaser {
      &.bn-weather-teaser--small {
        display: block;
        .bn-weather-teaser__title {
          display: block;
        }
        .bn-weather-teaser__forecast {
          margin-left: 0;
        }
      }
    }
    // newsletter
    .bn-newsletter {
      &.bn-newsletter--small {
        .bn-newsletter__title {
          display: none;
        }
        .bn-newsletter__form {
          width: 100%;
        }
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v1 {
        .bn-pipe {
          display: none;
        }
        .bn-break {
          display: inline;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    // weather teaser
    .bn-weather-teaser {
      &.bn-weather-teaser--small {
        font-size: 1.4em;
      }
    }
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 30px;
        }
      }
    }
  }
}